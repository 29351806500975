export const useCaseOptions = [
  {
    label: 'Scientist/Researcher',
    value: 'Scientist/Researcher'
  },
  {
    label: 'Geospatial Engineer',
    value: 'Geospatial Engineer'
  },
  {
    label: 'Policymaker',
    value: 'Policymaker'
  },
  {
    label: 'Analyst',
    value: 'Analyst'
  },
  {
    label: 'Professor/Teacher/Educator',
    value: 'Professor/Teacher/Educator'
  },
  {
    label: 'Student',
    value: 'Student'
  },
  {
    label: 'Hobbiest',
    value: 'Hobbiest'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const sectorOptions = [
  {
    label: 'Commercial',
    value: 'Commercial'
  },
  {
    label: 'Government',
    value: 'Government'
  },
  {
    label: 'Education',
    value: 'Education'
  },
  {
    label: 'Nonprofit',
    value: 'Nonprofit'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
