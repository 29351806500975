import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const Alert = ({
  type = 'info',
  className,
  message = 'Welcome to ORNL LandScan'
}) => {
  return (
    <div className={`alert alert-${type} ${className || ''}`}>
      <FontAwesomeIcon icon={faExclamationTriangle} /> {message}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default Alert;
