import { useEffect, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';

/**
 * useForm
 * @description Grabs data from form fields and saves them in state
 * {object} initial
 */
export default function useForm (initial = {}) {
  const [inputs, setInputs] = useState(initial);
  const initialValues = Object.values(initial).join('');
  const countries = useMemo(() => countryList().getData(), []);

  // Including `initial` in the dependency list causes a tight loop that locks
  // up the page when you attempt to interact with a field on the form
  useEffect(() => {
    setInputs(initial);
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * handleChange
   * @description Grabs data from form inputs and saves them in state
   * {object} initial
   */

  function handleChange (e) {
    const { value, name } = e.target;

    setInputs({
      ...inputs,
      [name]: value
    });
  }

  /**
   * handleSelectOnChange
   * @description Grabs data from drop down inputs and saves them in state
   * {object} e
   * {object} fields
   */

  function handleSelectOnChange (e, fields) {
    const { value } = e;
    const { name } = fields;

    setInputs({
      ...inputs,
      [name]: value
    });
  }

  return {
    inputs,
    countries,
    handleChange,
    handleSelectOnChange
  };
}
