import React from 'react';
import PropTypes from 'prop-types';

/**
 * Modal
 * @description brings up a pop up form or message for users
 * {string} type
 */

function Modal ({
  children,
  className,
  header,
  subHeader,
  isOpen = false,
  toggleModal
}) {
  return (
    <div className="modal-background">
      <section
        className={`modal ${className} ${isOpen && 'toggle'}`}
        aria-hidden={!isOpen}
      >
        <div className="modal-header">
          {header}
          {subHeader && subHeader}
          <button
            className="close-modal"
            onClick={toggleModal}
            aria-label="Close Modal"
          ></button>
        </div>
        <div className="modal-content">{children}</div>
      </section>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  type: PropTypes.string,
  toggleModal: PropTypes.func,
  className: PropTypes.string,
  header: PropTypes.object,
  subHeader: PropTypes.object,
  isOpen: PropTypes.bool
};
export default Modal;
