import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import logo from 'assets/images/ornl-nav-logo.png';
import Download from 'components/Download';
import Modal from 'components/Modal';

export default function Navbar () {
  const [modalOpen, setModalOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  // when a user clicks the download button, open a modal with the download form
  const handleDownloadClick = () => {
    setToggle(false);
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <nav className="nav">
        <section className="logo">
          <Link to="/">
            <img src={logo} alt="ornl logo" />
          </Link>
        </section>
        <section className="nav-links">
          <Link to="/">Home</Link>
          <button onClick={() => setToggle(!toggle)} className="about-landscan">
            Resources <FontAwesomeIcon icon={faCaretDown} />
          </button>
          <button className="download" onClick={handleDownloadClick}>
            <FontAwesomeIcon icon={faDownload} />
            &nbsp;&nbsp;Download
          </button>
          {toggle && (
            <div className="about-dropdown">
              <Link to="/about" className="about-landscan about-dropdown-item">
                About LandScan
              </Link>
              <Link
                to="/metadata"
                className="metadata-landscan about-dropdown-item"
              >
                Metadata
              </Link>
              <Link
                to="/citations"
                className="citations-landscan about-dropdown-item"
              >
                Citations
              </Link>
              <Link
                to="/licensing"
                className="metadata-landscan about-dropdown-item"
              >
                Licensing
              </Link>
            </div>
          )}
        </section>
      </nav>
      {modalOpen && (
        <Modal
          className="download"
          isOpen={modalOpen}
          toggleModal={handleDownloadClick}
          header={<h2>Downloading LandScan Data is Free!</h2>}
          subHeader={<p>We just need some basic details from you...</p>}
        >
          <Download />
        </Modal>
      )}
    </>
  );
}
