import React, { useEffect, useState } from 'react';
import { Button, Form, FormInput, FormRow } from 'fogg/ui';
import axios from 'axios';

import useForm from '../hooks/useForm';
import useToggle from '../hooks/useToggle';
import productData from 'data/products.json';
import { sectorOptions, useCaseOptions } from '../data/profile';
import Alert from './Alert';

import { FaSpinner } from 'react-icons/fa';

export default function Download () {
  // Alert/Error text: set to string to display error message, undefined to hide
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const { inputs, handleChange, handleSelectOnChange } = useForm({
    email: '',
    primaryUse: '',
    sector: '',
    products: '',
    year: '',
    time: '',
    hdCountry: ''
  });

  const { toggle, toggleOpen } = useToggle(false);

  useEffect(() => {
    if (inputs?.products === productData.LANDSCAN_GLOBAL_LABEL) {
      toggleOpen();
      inputs.time = '';
    }
    if (inputs?.products === productData.LANDSCAN_HD_LABEL) {
      toggleOpen();
      inputs.year = '';
      inputs.time = '';
    }
    if (inputs?.products === productData.LANDSCAN_USA_LABEL) {
      toggleOpen();
      inputs.year = '';
    }
  }, [inputs, toggle, toggleOpen]);

  function downloadData (downloadUrl) {
    window.open(downloadUrl, '_top');
  }

  /**
   * @typedef {Object} RegistrationInfo
   * @property {string} email
   * @property {string} primary_use
   * @property {string} sector
   * @property {string} product
   * @property {string} productModifier1
   * @property {string} [productModifier2]
   */

  function handleSubmit (e, inputs) {
    e.preventDefault();
    setIsLoading(true);
    const product = inputs.products.value[0];
    const year = inputs?.year?.value[0];
    const country = inputs?.hdCountry?.value[0];
    const time = inputs?.time?.value[0];

    const productInfo = {};
    if (product === productData.LANDSCAN_GLOBAL_LABEL && year) {
      productInfo.product = 'global';
      productInfo.productModifier1 = year.toString();
    }
    if (product === productData.LANDSCAN_HD_LABEL && country) {
      productInfo.product = 'hd';
      productInfo.productModifier1 = country.split('/')[0];
      productInfo.productModifier2 = country.split('/')[1];
    }
    if (product === productData.LANDSCAN_USA_LABEL && time && year) {
      productInfo.product = 'usa';
      productInfo.productModifier1 = year.toString();
      productInfo.productModifier2 = time.toLowerCase();
    }

    /** @type RegistrationInfo */
    const registrationInfo = {
      email: inputs.email.value,
      primary_use: inputs.primaryUse.value[0],
      sector: inputs.sector.value[0],
      ...productInfo
    };

    axios
      .post(
        `${process.env.API_ENDPOINT}/download`,
        JSON.stringify(registrationInfo)
      )
      .then(res => {
        downloadData(res.data);
        setIsLoading(false);
        setDownloadSuccess(true);
        setTimeout(() => {
          setDownloadSuccess(false);
        }, 8000)
      })
      .catch(err => {
        setIsLoading(false);
        if (err.request) {
          console.log('request error', err.request);
        }
        if (err.response) {
          console.log('response error', err.response);
        }
        console.error(err, err.stack);

        // @ts-ignore
        setErrorMessage('Failed to download product assets.');
      });
  }

  // Display version number with label if available
  const HDList = productData.hdCountryOptions.map(option => {
    const version = option.version || 'v1';

    return {
      ...option,
      value: `${option.longname}/${version}`,
      label: `${option.label} (${version})`
    };
  });

  // Filter out specific years for LandScan USA (2016-2020 currently)
  const yearOptionsUSA = productData.yearOptions.filter(option =>
    productData.usYearOptions.includes(option.value)
  );

  return (
    <Form className="download-form" method="" onSubmit={handleSubmit}>
      {errorMessage && <Alert message={errorMessage} type="danger" />}
      <fieldset>
        <FormInput
          id="email"
          className="download-form-input"
          label="Email Address:"
          type="email"
          required={true}
          placeholder="Please enter email address..."
          name="email"
          value={inputs.email}
          onChange={handleChange}
        />
        <FormRow>
          <FormInput
            id="sector"
            className="download-form-input"
            label="Work Sector:"
            type="select"
            options={sectorOptions}
            placeholder="Please select"
            required={true}
            name="sector"
            clearable={false}
            // value={inputs.sector}
            onChange={handleSelectOnChange}
          />
          <FormInput
            id="primary-use"
            className="download-form-input"
            label="User Type:"
            type="select"
            options={useCaseOptions}
            placeholder="Please select"
            required={true}
            name="primaryUse"
            clearable={false}
            // value={inputs.primaryUse}
            onChange={handleSelectOnChange}
          />
        </FormRow>
        <FormInput
          id="products"
          className="download-form-input"
          label="Select Product(s):"
          type="select"
          options={productData.productsOptions}
          required={true}
          name="products"
          clearable={false}
          // value={inputs.products}
          onChange={handleSelectOnChange}
        />
        {toggle && inputs?.products === productData.LANDSCAN_GLOBAL_LABEL && (
          <FormInput
            id="year"
            className="download-form-input"
            label="Select Year:"
            type="select"
            options={productData.yearOptions}
            required={true}
            name="year"
            clearable={false}
            // value={inputs.year}
            onChange={handleSelectOnChange}
          />
        )}
        {toggle && inputs?.products === productData.LANDSCAN_HD_LABEL && (
          <FormInput
            id="hd-country"
            className="download-form-input"
            label="Select Country:"
            type="select"
            options={HDList}
            required={true}
            name="hdCountry"
            clearable={false}
            // value={inputs.hdCountry}
            onChange={handleSelectOnChange}
          />
        )}
        {toggle && inputs?.products === productData.LANDSCAN_USA_LABEL && (
          <FormRow>
            <FormInput
              id="time"
              className="download-form-input"
              label="Select Day or Night:"
              type="select"
              options={productData.dayNightOptions}
              required={true}
              name="time"
              clearable={false}
              // value={inputs.time}
              onChange={handleSelectOnChange}
            />
            <FormInput
              id="year"
              className="download-form-input"
              label="Select Year:"
              type="select"
              options={yearOptionsUSA}
              required={true}
              name="year"
              clearable={false}
              // value={inputs.year}
              onChange={handleSelectOnChange}
            />
          </FormRow>
        )}
        <Button className="download-submit" type="submit" disabled={isLoading}>
          Download
        </Button>
        {isLoading ? 
        <div className="loading-spinner">
          <FaSpinner />
        </div> : null}
        {downloadSuccess ? <p className="download--success">Download Successful!</p> : null }
      </fieldset>
    </Form>
  );
}
