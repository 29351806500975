import { useState } from 'react';

/**
 * useToggle
 * @description Toggle things open and closed or on and off
 * {boolean} initialToggle
 */

export default function useToggle (initialToggle = false) {
  const [toggle, setToggle] = useState(initialToggle);

  /**
   * switchToggle
   * @description Toggles open/close, on/off
   */

  function switchToggle () {
    setToggle(!toggle);
  }

  /**
   * toggleOpen
   * @description Toggles open/on
   */

  function toggleOpen () {
    setToggle(true);
  }

  /**
   * toggleClose
   * @description Toggles open/on
   */

  function toggleClose () {
    setToggle(false);
  }

  return {
    toggle,
    switchToggle,
    toggleOpen,
    toggleClose
  };
}
