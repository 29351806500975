/**
 * ClassName
 * @description Class helper to dynamically create a components className
 */

class ClassName {
  constructor (defaultClassName) {
    this.base = defaultClassName;
    this.classes = new Set();
    if (this.base) this.classes.add(this.base);
  }

  setBase (className) {
    this.base = className;
  }

  add (className) {
    if (!className) return this.classes;
    const split = className.split(' ');
    split.forEach(i => this.classes.add(i));
    return this.classes;
  }

  child (append) {
    return `${this.base}-${append}`;
  }

  get string () {
    return Array.from(this.classes).join(' ');
  }
}

export default ClassName;
