import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

const Container = ({ children, className, type, color }) => {
  const containerClassName = new ClassName('container');

  if (typeof type === 'string') {
    type = [type];
  }

  if (Array.isArray(type)) {
    type.forEach(t => containerClassName.add(containerClassName.child(t)));
  }

  if (color) {
    containerClassName.add(containerClassName.child('color'));
    containerClassName.add(containerClassName.child(`color-${color}`));
  }

  if (typeof className === 'string') {
    containerClassName.add(className);
  }

  return <div className={containerClassName.string}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default Container;
